

































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiDistributionGoodsDetails,
    apiDistributionGoodsSet
} from '@/api/distribution/distribution'
@Component
export default class AddSupplier extends Vue {
    /** S Data **/

    id!: any //当前的ID

    distributionData = {
        ratio_data: [
            {
                level_id: 1,
                item_id: 1,
                self_ratio: 10,
                first_ratio: 10,
                second_ratio: 10
            },
            {
                level_id: 1,
                item_id: 2,
                self_ratio: 20,
                first_ratio: 20,
                second_ratio: 20
            },
            {
                level_id: 4,
                item_id: 1,
                self_ratio: 30,
                first_ratio: 30,
                second_ratio: 30
            }
        ], //佣金比例
        id: '',
        goods_id: '',
        rule: 1, // 佣金规则1是默认  2是单独
        is_distribution: [] //是否参与 0 不 1是
    }

    goodsSelectData: any = []

    rules = {
        //表单验证
        name: [
            {
                required: true,
                message: '请输入优惠券名称',
                trigger: ['blur', 'change']
            }
        ],
        money: [
            {
                required: true,
                message: '请输入优惠券面额',
                trigger: ['blur', 'change']
            }
        ],
        send_total: [
            {
                required: true,
                message: '请输入发放数量',
                trigger: ['blur', 'change']
            }
        ]
    }

    /** E Data **/

    /** S Method **/

    submit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): any => {
            if (!valid) {
                return
            }

            this.couponAdd()
        })
    }

    // 设置时初始化数据结构成为接口适用的数据
    base(data: any) {
        const obj: any = {
            goods_id: data.id || data.goods_id,
            is_distribution: data.is_distribution,
            rule: data.rule,
            ratio_data: []
        }

        Object.keys(data.ratio_data).map((res, index) => {
            for (let i = 0; i < data.ratio_data[index].items.length; i++) {
                const el = data.ratio_data[index].items[i]
                obj.ratio_data.push({
                    level_id: el.level_id,
                    item_id: el.item_id,
                    self_ratio: el.self_ratio,
                    first_ratio: el.first_ratio,
                    second_ratio: el.second_ratio
                })
            }
        })

        return obj
    }

    // 设置分销商品
    couponAdd() {
        const edit = this.base(this.distributionData)

        apiDistributionGoodsSet({ ...edit })
            .then(res => {
                this.$message.success('设置成功!')
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch(() => {
                this.$message.error('设置失败!')
            })
    }

    // 获取分销商品详情
    getCouponInfo() {
        apiDistributionGoodsDetails({ id: this.id }).then((res: any) => {
            this.distributionData = { ...res }
        })
    }

    /** E Method **/

    created() {
        this.id = this.$route.query.id
        this.id && this.getCouponInfo()
    }
}
